import { Component } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { reportDefinition } from '../../../resources/user/user-reports/report.definition'

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent extends AbcCreateEditComponent {
  definition: ResourceDefinition = reportDefinition

  fields: Field[] = [
    {
      id: 'userId',
      label: `Collaborateur`,
      placeholder: `Choisir le collaborateur...`,
      properties: {
        value: 'userId'
      },

      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {}),
      className: 'is-12 p-x-0-mobile',
      validators: []
    }
  ]

  reportPath: string

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private componentResourceService: ResourceService,
    private componentFlashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }

  submit() {
    this.reportPath = null
    this.loadingSubmit = true
    this.componentResourceService
      .store(
        `reports/generate-partner-report?userId=${this.form.value.userId}`,
        {}
      )
      .toPromise()
      .then((res) => {
        this.loadingSubmit = false
        this.reportPath = environment.storagePath + res.path
      })
      .catch((err) => {
        this.loadingSubmit = false
        this.componentFlashMessageService.error(err.error.message)
      })
  }
}
