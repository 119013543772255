<h1 class="title is-1">Demo: génération de rapport</h1>
<p>⚠ This feature is for demo purposes only. Do not use in production.</p>

<form [formGroup]="form" *ngIf="form && !loadingForm">
  <div class="card m-b-lg">
    <!-- Fields -->
    <div class="columns flex flex-wrap">
      <ng-container *ngFor="let field of resolvedFields">
        <ng-container *abcHasPermission="field.permission">
          <div
            class="column column--classic"
            [ngClass]="field.className"
            *ngIf="!field.hidden"
          >
            <abc-input
              [type]="field.inputType"
              [accept]="field.accept"
              [initialValue]="field.initialValue"
              [searchResources]="field.searchResources"
              [resourceName]="definition.slug"
              [searchParams]="field.searchParams"
              [maxSelectedItems]="field.maxSelectedItems"
              [selectOptions]="field.selectOptions"
              [helpText]="field.helpText"
              [min]="field.min"
              [max]="field.max"
              [label]="field.label"
              [placeholder]="field.placeholder"
              [readonly]="field.readonly"
              [validators]="field.validators"
              [showErrors]="showErrors"
              (valueChanged)="onValueChanged($event, field)"
            ></abc-input>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <button
      class="button is-primary is-hidden-touch"
      (click)="submit()"
      [ngClass]="{ 'is-loading': loadingSubmit }"
    >
      Générer le rapport
    </button>

    <a [href]="reportPath" *ngIf="reportPath" target="_blank"
      >Ouvrir le rapport</a
    >
  </div>
</form>
